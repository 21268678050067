import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from "../../environments/environment"

@Component({
	selector: 'app-manage',
	templateUrl: './manage.component.html',
	styleUrls: ['./manage.component.scss']
})
export class ManageComponent implements OnInit {

	@Input('categories') categories;

	MailChimpEndpoint:string = "https://caa.api.mailchimp.com/3.0/";

	selectedOptions;
	User:string;
	ValidEmail: boolean;
	Details: any;

	constructor(
		private _Http: HttpClient,
		private _FormBuilder: FormBuilder
	) { }

	ngOnInit() {
		this.Details = this._FormBuilder.group({
			email: new FormControl(null, Validators.compose([Validators.email, Validators.required])),
			auth: new FormControl(null, Validators.required)
		})
	}

	async SubmitDetails()
	{
		if(this.Details.controls.email.valid) 
		{
			this.ValidEmail = true;

			let MemberID = "9209b54fa67cd4c11f3dfe0bdda0ead6"

			let Url = this.MailChimpEndpoint + "lists/b2d982b9e4/members/" + MemberID;
			this._Http.get<any[]>(Url).subscribe(Response => {
				
			})
		}
	}

	onNgModelChange(_Event) {}

	UpdateDetails() {}

}
