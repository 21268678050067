import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'alertFilter',
	pure: false
})
export class AlertFilterPipe implements PipeTransform {

	transform(items: Array<any>, filter: any): any {

		if (!items) return null

		if (!filter)
			return items;

		items = items.filter(item => {
			if (item._fl_meta_.status == "publish") {
				if (filter.priority == 'all' || (filter.priority == 'yes' && item.urgent) || (filter.priority == 'no' && !item.urgent)) {
					if (filter.date && item.date > filter.date) {
						if (filter.ref == '' && filter.search == '') {
							// Could do this all in one line, but it was hard to tell what was going on...
							if (!filter.category)
								return item;
							else if (item.expire == "" || item.expire > filter.currentDate)
								return item;
						}

						if ((filter.ref != '' && item.refNo.toLowerCase().includes(filter.ref.toLowerCase())) || (filter.search != '' && item.title.toLowerCase().includes(filter.search.toLowerCase())) || (filter.search != '' && item.body.toLowerCase().includes(filter.search.toLowerCase())))
							return item;
					}
				}
			}
		});

		if (filter.sort == 'datedesc') {
			items = items.sort((a, b) => {
				if (a.date == b.date) return (a.refNo.toLowerCase() < b.refNo.toLowerCase()) ? 1 : -1;
				return (a.date < b.date) ? 1 : -1
			});
		}
		else if (filter.sort == 'dateasc') {
			items = items.sort((a, b) => {
				if (a.date == b.date) return (a.refNo.toLowerCase() > b.refNo.toLowerCase()) ? 1 : -1;
				return (a.date > b.date) ? 1 : -1
			});
		}
		else if (filter.sort == 'refdesc') {
			items = items.sort((a, b) => (a.refNo.toLowerCase() < b.refNo.toLowerCase()) ? 1 : -1);
		}
		else if (filter.sort == 'refasc') {
			items = items.sort((a, b) => (a.refNo.toLowerCase() > b.refNo.toLowerCase()) ? 1 : -1);
		}

		items = items.slice(0, filter.amount);

		return items;
	}
}
