import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FlamelinkService } from '../services/flamelink.service';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

	alert;
	public pages;
	public allPages;

	constructor(
		private route: ActivatedRoute,
		private flService: FlamelinkService,
		private _Title: Title
	) {
	}

	ngOnInit() {}

	ngAfterViewInit() {
		let id = this.route.snapshot.paramMap.get('id')
		this.get(id);
	}

	get(id) {
		this.flService.alert(id).subscribe(alert => {
			this.alert = alert
			this._Title.setTitle("Skywise - Alert: " + this.alert.title);
		})
	}
}
