import { BrowserModule,  } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFlamelinkModule } from 'angular-flamelink';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { HomeComponent } from './home/home.component';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { MatButtonModule, MatToolbarModule, MatCheckboxModule, MatListModule, MatFormFieldModule, MatInputModule, MatSidenavModule, MatCardModule, MatGridListModule, MatRadioModule, MatIconModule } from '@angular/material';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertsComponent } from './alerts/alerts.component';
import { CategoryComponent } from './category/category.component';
import { AlertComponent } from './alert/alert.component';
import { FilterPipe } from './pipes/filter.pipe';
import { EmailSubscribeFormComponent } from './email-subscribe-form/email-subscribe-form.component';
import { AlertFilterPipe } from './pipes/alert-filter.pipe';
import { CategoryFilterPipe } from './pipes/category-filter.pipe';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import { PageFilterPipe } from './pipes/page-filter.pipe';
import { ManageComponent } from './manage/manage.component';
import { SubscribeComponent } from './subscribe/subscribe.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AlertsComponent,
    CategoryComponent,
    AlertComponent,
    FilterPipe,
    EmailSubscribeFormComponent,
    AlertFilterPipe,
    CategoryFilterPipe,
    PageFilterPipe,
    ManageComponent,
    SubscribeComponent
  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFlamelinkModule,
    AngularFireAuthModule,
    HttpClientModule,
    HttpClientJsonpModule,
    MatButtonModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatSidenavModule,
    MatGridListModule,
    MatRadioModule,
    MatCardModule,
    MatIconModule,
    FormsModule,
	  ReactiveFormsModule
  ],
  providers: [
	  DatePipe,
	  AlertFilterPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
