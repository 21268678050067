import { Component, OnInit, ApplicationRef } from '@angular/core';
import { AngularFlamelink } from 'angular-flamelink';
import { FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FlamelinkService } from '../services/flamelink.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

interface MailChimpResponse {
	result: string;
	msg: string;
}

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

	public pages
	public categories;

	public pageTitle = "Home";

	emailControl = new FormControl('', [Validators.required, Validators.email,]);
	nameControl = new FormControl('', [Validators.required]);

	submitted = false;

	constructor(
		private flamelink: AngularFlamelink,
		private flService: FlamelinkService,
		private http: HttpClient,
		private ref: ApplicationRef,
		private _Router: Router,
		private _Route: ActivatedRoute,
		private _Title: Title
	) {
		if (this._Router.getCurrentNavigation().extras.state) {
			this.pageTitle = this._Router.getCurrentNavigation().extras.state.data;
		}
	}

	ngOnInit() {
		this.pages = this.flService.pages();
		this.categories = this.flService.categories();

		this._Route.params.subscribe(Params => {
			if (Params.page)
				this.pageTitle = Params.page;
			else
				this.pageTitle = "Home";

			this._Title.setTitle("Skywise - " + this.pageTitle);
		})
	}

	setPage(title) {
		this.pageTitle = title;
	}

}