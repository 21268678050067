import { Injectable } from '@angular/core';
import { AngularFlamelink } from 'angular-flamelink';
import { AngularFirestore } from '@angular/fire/firestore';
import { DatePipe } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class FlamelinkService {

	count = []

	constructor(
		public flamelink: AngularFlamelink,
		public afs: AngularFirestore,
		public _DatePipe: DatePipe

	) { }

	pages(filters?): Promise<any> {
		return new Promise((resolve) => {
			if (filters)
				this.flamelink.valueChanges({ schemaKey: 'pages', filters: [filters] }).subscribe(Data => {
					resolve(Data);
				})
			else
				this.flamelink.valueChanges({ schemaKey: 'pages' }).subscribe(Data => {
					resolve(Data);
				})
		})

	}

	categories(): Promise<any> {
		return new Promise((resolve) => {
			this.flamelink.valueChanges({ schemaKey: 'categories' }).subscribe(Data => {
				resolve(Data);
			})
		})
	}

	alerts(category): Promise<any> {
		return new Promise((resolve) => {
			console.log(Date.now());
			console.log(this._DatePipe.transform(Date.now() - (1000 * 60 * 60 * 24 * 365), "yyyy-MM-ddTHH:mm:ssZ"))
			if (category == 'any')
				this.flamelink.valueChanges({ schemaKey: 'alerts', filters: [['date', '>=', this._DatePipe.transform(Date.now() - (1000 * 60 * 60 * 24 * 365), "yyyy-MM-ddTHH:mm:ssZ")]] }).subscribe(Data => {
					resolve(Data);
				})
			else
				this.flamelink.valueChanges({ schemaKey: 'alerts', filters: [['categories', 'array-contains', category], ['date', '>=', this._DatePipe.transform(Date.now() - (1000 * 60 * 60 * 24 * 365), "yyyy-MM-ddTHH:mm:ssZ")]] }).subscribe(Data => {
					resolve(Data);
				})
		})
	}

	alert(id) {
		return this.afs.collection('fl_content').doc(id).valueChanges()
	}

	categoryCount(category, id) {
		this.alerts(category).then(alerts => {
			let data = {

				[id]: alerts.length

			}

			this.count = this.count.concat(data)

		})

	}

}
