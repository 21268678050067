import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AlertsComponent } from './alerts/alerts.component';
import { AlertComponent } from './alert/alert.component';
import { SubscribeComponent } from './subscribe/subscribe.component';

const routes: Routes = [
	{ path: '', redirectTo: '/home', pathMatch: 'full' },
	{ path: 'home', component: HomeComponent },
	{
		path: 'alerts',
		children: [
			{
				path: '',
				component: AlertsComponent
			},
			{
				path: 'alert/:id',
				children: [
					{
						path: '',
						component: AlertComponent
					}
				]
			}

		]
	},
	{
		path: 'subscribe', component: SubscribeComponent
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
