import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { FlamelinkService } from './services/flamelink.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

	title = 'skywise-web';
	public pages;
	public Route;


	constructor(
		private flService: FlamelinkService,
		private _Router: Router,
		public _ActivatedRoute: ActivatedRoute
	) {	
		
	}

	ngOnInit() {

		this.pages = this.flService.pages();

		this._Router.events.subscribe((evt) => {
            if ((evt instanceof NavigationEnd)) {
				const element : HTMLElement = document.querySelector("#main");
				if(element) 
				{
					element.tabIndex = 0;
					element.focus();
					element.tabIndex = -1;

					window.scrollTo(0,0);
				}
			}
		});

		this.Route = this._Router.url;
		// subscribe to cookieconsent observables to react to main events
		

	}

	ngOnDestroy() {
		
	}

}
