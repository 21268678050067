import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	  name: 'categoryFilter',
	  pure: false
})
export class CategoryFilterPipe implements PipeTransform {

  	transform(items: any, filter: any): any {
		if(!items) return null;

		items.sort((a,b) => (a.name > b.name) ? 1 : -1);

		if(!filter) return items;

    	return items = items.filter(item => {
			if(item.name.toLowerCase().includes(filter.toLowerCase()))
				return item;
		})
	}

}
