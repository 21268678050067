// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDmdZHZZWfd3l-b2x9Bfm068xGmf_BDjjc",
    authDomain: "skywise-db453.firebaseapp.com",
    databaseURL: "https://skywise-db453.firebaseio.com",
    projectId: "skywise-db453",
    storageBucket: "",
    messagingSenderId: "106993681236"
  },
  mailchimp: {
    key: '6f81174a67ae12aeb6d243944c615430-us4',
    list: 'b2d982b9e4'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
