import { Component, OnInit, ApplicationRef } from '@angular/core';
import { AngularFlamelink } from 'angular-flamelink';
import { FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FlamelinkService } from '../services/flamelink.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-subscribe',
	templateUrl: './subscribe.component.html',
	styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {

	public pages
	public categories;

	public pageTitle = "Email Notifications";

	constructor(
		private flService: FlamelinkService,
	) {}

	ngOnInit() {
		this.pages = this.flService.pages();
		this.categories = this.flService.categories();
	}

}
