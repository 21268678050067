import { Component, NgZone, OnChanges, OnInit, AfterContentInit, AfterViewInit, ViewChild, ApplicationRef } from '@angular/core';
import { AngularFlamelink } from 'angular-flamelink';
import { AngularFirestore } from '@angular/fire/firestore';
import { FlamelinkService } from '../services/flamelink.service';
import { FormGroup, FormControl } from '@angular/forms'
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser'
import { AlertFilterPipe } from '../pipes/alert-filter.pipe';
import { Router } from '@angular/router';

@Component({
	selector: 'app-alerts',
	templateUrl: './alerts.component.html',
	styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnChanges, OnInit, AfterContentInit, AfterViewInit {

	public pages;
	public allPages;
	public categories;
	public allAlerts;
	public alerts;
	public Loaded: boolean = false;

	public Route;

	opened: boolean = false;
	cat = 'all';
	id;
	subtitle = '';
	catCount;

	AlertsPerPage = 10;

	CategorySearch = new FormControl('');
	CategoryFilter;

	public Filter = {
		sort: 'datedesc',
		ref: '',
		search: '',
		date: '',
		currentDate: '',
		priority: 'all',
		category: false,
		amount: 10
	};

	FilterForm = new FormGroup({
		SortBy: new FormControl('datedesc'),
		RefSearch: new FormControl(''),
		Search: new FormControl(''),
		Priority: new FormControl('all')
	})
	ShowFilters: boolean = true;

	TruncateLength = 300;

	constructor(
		private flamelink: AngularFlamelink,
		private afs: AngularFirestore,
		private flService: FlamelinkService,
		private ref: ApplicationRef,
		private zone: NgZone,
		private _DatePipe: DatePipe,
		private _Title: Title,
		private _AlertFilter: AlertFilterPipe,
		private _Router: Router
	) {
		_Title.setTitle("Skywise - Alerts");
	}

	ngOnChanges() {

	}

	ngOnInit() {
		this.Route = this._Router.url;

		this.flService.pages(['title', '==', 'Alerts']).then(Data => {
			this.pages = Data;
		});

		this.categories = this.flService.categories();

		let year = (parseInt(this._DatePipe.transform(Date.now(), 'yyyy')) - 1).toString();
		this.Filter.date = year + '-' + this._DatePipe.transform(Date.now(), 'MM-ddThh:mm:ssZ');
		this.Filter.currentDate = this._DatePipe.transform(Date.now(), 'yyyy-MM-ddThh:mm:ssZ');

		this.FilterForm.valueChanges.subscribe(Data => {
		})

		this.CategorySearch.valueChanges.subscribe(Data => {
		})
	}

	ngAfterContentInit() {
		this.loadAlerts();
	}

	ngAfterViewInit() {

	}

	categorySelect(ev) {
		this.Filter.amount = this.AlertsPerPage;

		if(ev == 'none') {
			this.alerts = [];
			this.allAlerts = [];
			return;
		}

		if (ev != 'all') {
			let category = this.afs.collection('fl_content').doc(ev.id).ref;
			this.flService.alerts(category).then(alerts => {
				this.allAlerts = alerts;
				this.alerts = this._AlertFilter.transform(alerts, this.Filter);
			});
			this.Filter.category = true;
			this.subtitle = ev.name;
		}
		else {
			this.subtitle = "All Alerts";
			this.flService.alerts('any').then(alerts => {
				this.allAlerts = alerts;
				this.alerts = this._AlertFilter.transform(alerts, this.Filter);
			});;
			this.Filter.category = false;
			this.cat = 'all';
		}
		
	}

	loadAlerts() {
		this.subtitle = "All Alerts";
		this.categorySelect(this.cat);
	}

	async loadMore() {
		this.Filter.amount += this.AlertsPerPage;

		this.alerts = this._AlertFilter.transform(this.allAlerts, this.Filter);

		let alert : HTMLElement = null;

		while(alert == null)
		{
			alert = document.querySelector('#alert' + (this.Filter.amount - this.AlertsPerPage).toString() + 'button');
			if(alert != null) alert.focus();

			await new Promise(resolve => setTimeout(resolve, 100));
		}
	}

	cleanContent(Text: string) {
		Text = Text.replace(/<[^>]*>/g, '');
		Text = Text.replace(/&nbsp;/g, " ");

		if(Text.length > this.TruncateLength + 100)
		{
			let t = Text;
			let i = t.indexOf(" ", this.TruncateLength - 1);

			switch(Text[i - 1])
			{
				case ".":
				case ",":
					i--;
					break;
			}

			Text = t.substr(0, i);
			Text = Text + "...";
		}

		return Text;
	}

	SetFilters() {
		this.Filter.sort = this.FilterForm.value.SortBy;
		this.Filter.ref = this.FilterForm.value.RefSearch;
		this.Filter.search = this.FilterForm.value.Search;
		this.Filter.priority = this.FilterForm.value.Priority;

		this.categorySelect(this.cat);
	}

	ResetFilters() {
		this.Filter.sort = 'datedesc';
		this.Filter.ref = '';
		this.Filter.search = '';
		this.Filter.priority = 'all';

		this.FilterForm.setValue({
			SortBy: 'datedesc',
			RefSearch: '',
			Search: '',
			Priority: 'all'
		});
	}

	SetCategorySearch() {
		this.CategoryFilter = this.CategorySearch.value;
	}
}
