import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'pageFilter'
})
export class PageFilterPipe implements PipeTransform {

	transform(Pages: Array<any>, Title: string): any {
		if (Pages == null) return null;

		if (Title) {
			return Pages.filter(Page => {
				if (Page.title == Title) return Page;
			})
		}
		else {
			return Pages.filter(Page => {
				if (Page.title != "Alerts" &&
					Page.title != "Home" &&
					Page.title != "Manage Subscription" &&
					Page.title != "Privacy Policy" &&
					Page.title != "Accessibility")
					return Page
			})
		}

	}

}
