import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';

const mailchimp = require('@mailchimp/mailchimp_marketing');

@Component({
	selector: 'app-email-subscribe-form',
	templateUrl: './email-subscribe-form.component.html',
	styleUrls: ['./email-subscribe-form.component.scss']
})

export class EmailSubscribeFormComponent implements OnInit{

	@Input('categories') categories;

	constructor(
		private _Http: HttpClient
	) { 
		mailchimp.setConfig({
			apiKey: "1742d74ff2de121ff7ff923feaad7cc2-us4",
			server: "us4",
		  });
	}

	ngOnInit() {
	}

	async Unsubscribe() {
		const response = await mailchimp.ping.get();
	}
}
