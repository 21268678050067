import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(alerts: any, category: string): any {

	if(!category || category == 'all') return alerts;

    return alerts.filter(alert => {

      if(alert.categories.includes(category)) {
        return
      }

    })
  }
}
